angular.module('App.Ui', [])

.directive 'hideOnActive', ->
    restrict: 'AC'
    scope:
        url: '@hideOnActive'
    controller: ($scope, $element) ->
        if location.pathname == $scope.url
            $element.remove()

        return


.directive 'selectToAnchor', ->
    scope: true
    restrict: 'AC'
    controller: [
        '$scope',
        ($scope) ->
            $scope.model =
                anchor: ""
    ]
    link: (scope, elm, attr) ->
        scope.model.prefix = attr.prefix

        scope.$watch 'model',
            ->
                s = scope.model
                window.location.hash = s.prefix + s.anchor
            true

        return


.directive 'selectToSubmit', [
    'GetMethodService'
    ($service) ->
        scope: true
        link: (scope, elm, attr) ->
            scope.model = $service.values

            scope.submit = ->
                elm[0].submit()

            return
]


.directive 'youtubeEmbed', ->
    restrict: 'EAC'
    scope:
        youtubeEmbed: '@'
        embedId: '@'
    link: (scope, element, attrs) ->
        element.bind 'click', ($event) ->
            do $event.preventDefault


            # doesn't work on staging as we arent tracking
            if ga?
                ga(
                    'send'
                    'event'
                    'video'
                    'play'
                    "youtu.be/#{ scope.youtubeEmbed }"
                )

            scope.videoEmbed = true

            filters = "?autoplay=1&rel=0"
            document.getElementById(scope.embedId).innerHTML =
                """
                <iframe
                    src="//www.youtube.com/embed/#{scope.youtubeEmbed}#{filters}"
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen=""
                    class="hero--item embed--item fill-x push-auto"
                ></iframe>
                """


.directive 'cisCarousel',
    [
        '$carouselService'
        ($carousel) ->
            restrict: 'EAC'
            replace: true
            transclude: true
            templateUrl: 'carousel_panel.html'
            scope:
                id: '@'
                extraClass: '@'
            link: (scope, element, attrs) ->
                $carousel.addPanel scope
    ]



.directive 'cisExpiryModal', [
    "vefaExpiryModalService"
    (modalService) ->
        restrict: 'E'
        scope: {}
        controllerAs: 'modal'
        bindToController:
            name: "@"
            suppress: "@"
        transclude: true
        templateUrl: "homepage_modal.html"
        controller: ->
            @service = modalService
            @service.init(@name, @suppress)
]

.directive 'stepExtended', ->
    restrict: 'A'
    scope: true
    controllerAs: 'panels'
    bindToController: true
    controller: [
        '$element'
        ($element) ->
            @hash_id = $element.attr("id")
            @switch = false
            @body = document.getElementsByTagName("body")[0].style

            @are_panels_switched = ->
                return @switch

            @toggle_panels = ->
                @switch = !@switch
                if @switch
                    if window.matchMedia("(min-width: 700px)").matches
                        @body.overflow = "hidden"
                    window.location.hash = ""
                    window.location.hash = @hash_id
                else
                    @body.overflow = ""
                    window.location.hash = ""
                    window.location.hash = @hash_id


            return
    ]
