angular.module('Vefa.details', [])

.service '$closeService', ->
    detailGroups = []

    @addToGroup = (obj) ->
        detailGroups.push obj

    @toggle = ->
        obj.toggle() for obj in detailGroups

    return


# when ng finds element, we have a details/accordion group
.directive 'vefaDetailsGroup', ->
    restrict: 'EAC' #<details-group>, <div details-group>, <class="details-group">
    scope: {}
    controller: [
        '$scope'
        '$element'
        ($scope, $element) ->
            group_members = [] # new set of group members

            @gotOpened = (selected) ->
                angular.forEach group_members, (member) ->
                    # if this member is not the one opened: close it
                    if selected != member
                        member.isOpen = false

            @addDetails = (el) ->
                # hook for details element to add itself to group
                group_members.push el

            @closeAll = ->
                angular.forEach group_members, (member) ->
                    # if this member is not the one opened: close it
                    member.isOpen = false

            return
    ]
    link: (scope, element, attrs, controller) ->
        scope.name = "details-group"


# when ng finds the detail element, run the directive
.directive 'details', ->
    restrict: 'EC' # either <details> or class="details"
    require: '^?detailsGroup' # can be part of a group of details
    transclude: true
    replace: true
    scope: # need to create new scope for multiple details elements
        isOpen: '@open'
        closeCtrl: '@'
        hideSummary: '@'
    templateUrl: 'details.html'
    controller: [
        '$scope'
        '$element'
        '$attrs'
        '$closeService'
        ($scope, $element, $attrs, $cS) ->
            $scope.name = "details"

            $scope.close = ->
                $scope.toggle()
                $scope.$emit 'detailsClose', { closed: true }

            $cS.addToGroup $scope

            $scope.toggle = ->
                $scope.isOpen = !$scope.isOpen # toggle open closed (see template ^)

            $scope.$on "closeUpdate", (event) ->
                $scope.toggle()

            return
    ]
    link: (scope, element, attrs, controller) ->
        # find the summary control and show it outside of the transcluded piece
        ngElem = angular.element element
        summary = ngElem.find 'summary'
        ngElem.prepend summary
        # allow clicks on summary to toggle the panel
        summary.bind 'click', ->
            scope.$apply ->
                scope.toggle()
                if scope.hideSummary
                    summary.unbind 'click'
                    summary.addClass 'a11y'

        # check to see if this is a part of a group (got to be a better way)
        if controller?
            controller.addDetails scope # add element to the group

        scope.$watch 'isOpen', ->
            # watch changes in isOpen value to set attribute on <details> (CSS hook)
            if scope.isOpen
                ngElem.attr "open", true
            # tell group this elem is open
            if controller?
                controller.gotOpened scope
            else
                ngElem.removeAttr "open"


.directive "vefaDetailsCloser", ->
    restrict: 'E'
    replace: true
    templateUrl: 'details-closer.html'
