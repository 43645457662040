angular.module('Vefa.modal', ['ngCookies'])

# --------------------------------------------------------------------------------------------------
# vefaModalService
# base service that defines how and when a modal window is shown and hidden
#
#
.service 'vefaModalService', [
    ->
        visibility = false
        loaded = false
        bodyLock = false

        init: ->
            visibility = true
            @toggleBodyLock()
        close: ->
            visibility = false
            loaded = false
            @toggleBodyLock()
        isVisible: -> visibility
        isLoaded: ->
            if visibility
                loaded = true
            return loaded
        toggleBodyLock: ->
            body = document.getElementsByTagName("body")[0].style
            if bodyLock
                body.overflow = ""
                body.position = ""
                bodyLock = false
            else
                body.overflow = "hidden"
                body.position = "fixed"
                bodyLock = true
]


# --------------------------------------------------------------------------------------------------
# vefaExpiryModalService
#
# extends vefaModalService
#
# adds additional logic to init() to set cookie to hide a modal window for a certain amount of days
# we want to reset on the day timeout (0:00:00) as we can not control when a user may/will visit
# the site again, to the millisecond is too limiting.
#
#
.service 'vefaExpiryModalService', [
    'vefaModalService'
    '$cookies'
    (modalService, $cookies) ->
        service = angular.copy(modalService)

        service.init = (name, suppressTime) ->
            current_time = new Date()
            cookie_expiresAt = new Date()
            cookie_expiresAt.setDate( cookie_expiresAt.getDate() + parseInt(suppressTime) )
            modalCookie = $cookies.get( name )

            # if no cookie is set, lets set the cookie with the current time
            # and continue to initialize the modal
            unless modalCookie
                $cookies.put( name, cookie_expiresAt.valueOf() )
                return modalService.init()

            # if there is a cookie and it's been force bypassed
            # do not load the modal and instead change the cookie to the present time
            if (modalCookie == "bypass")
                $cookies.put( name, cookie_expiresAt.valueOf() )
                return

            # if there is a cookie and the current time is greater than the cookie time,
            # the cookie is expired, delete it, and reinitialize the modal,
            # setting a new cookie with the current time
            if (modalCookie and parseInt(suppressTime) == 0) or (current_time > modalCookie)
                $cookies.remove( name )
                service.init( name, suppressTime )

        return service
]
