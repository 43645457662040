"use strict"

angular.module('cis',
    [
        'App.Ui'
        'Vefa.responsive'
        'Vefa.djangoAjax'
        'Vefa.carousel'
        'Vefa.ui'
        'Vefa.modal'
        'Vefa.details'
        'Vefa.ajaxinterceptor'
        'Vefa.googleAnalytics'
    ]
)

.constant 'BREAKPOINTS',
    condensed: "43.8125em"
    # enhanced uses condensed and extended
    extended: "61.75em"


.config([
    "$locationProvider"
    "$compileProvider"
    "$interpolateProvider"
    ($locationProvider, $compileProvider, $interpolateProvider) ->
        $locationProvider.html5Mode(
            enabled: true
            requireBase: false
            rewriteLinks: false
        )

        $compileProvider.debugInfoEnabled(false)

        $interpolateProvider.startSymbol('[*')
        $interpolateProvider.endSymbol('*]')
])


.service 'GetMethodService', [
    '$location'
    '$http'
    '$sce'
    ($location, $http, $sce) ->
        values: $location.search()

        bindHTML: (html) ->
            $sce.trustAsHtml( html )

        getJSONfromURL: (url, cb) ->
            $http.get(url)
                .success cb

]


.service 'GetService', [
    '$http'
    '$sce'
    ($http, $sce) ->
        bindHTML: (html) ->
            $sce.trustAsHtml( html )

        getJSONfromURL: (url, cb) ->
            $http.get(url)
                .success cb
]

.value 'PressTitles',
    "published_date": "Oldest to Newest"
    "title": "A to Z"
    "-title": "Z to A"
    "press": "Press Releases"
    "news": "News Stories"


.directive 'pressRoomTitle', [
    'GetMethodService'
    'PressTitles'
    ($service, titles) ->
        controllerAs: 'title'
        controller: ->
            @filter = ->
                filter_check = $service.values["filter"]
                filter = "#{titles['press']} & #{titles['news']}"

                if filter_check? and filter_check != ""
                    filter = titles[$service.values["filter"]]

                return "All #{filter}"

            @sort = ->
                sort_check  = $service.values["sort"]
                sort = ""

                if sort_check? and sort_check != ""
                    sort = "(#{titles[$service.values["sort"]]})"

                return sort

]


.directive 'matchingGiftPreview', [
    'GetService'
    ($queryService) ->
        controllerAs: "profile"
        scope: {}
        bindToController:
            url: "@matchingGiftPreview"
            company: "@matchingGiftCompany"
        templateUrl: "matching-gift-preview.html"
        controller: () ->
            testForArray = ( prop ) ->
                if angular.isArray( prop )
                    return prop[0]
                else
                    return prop


            @is_hidden = true
            @toggle_display = ->
                @is_hidden = !@is_hidden

            $queryService.getJSONfromURL(
                @url,
                (data) =>
                    profile = data["company"]

                    @giftratio = testForArray( profile.giftratios.giftratio )
                    @min_matched = testForArray( profile.giftratios.minimum_amount_matched )
                    @max_matched = testForArray( profile.giftratios.maximum_amount_matched )
                    @total_matched = testForArray( profile.giftratios.total_amount_per_employee )
                    @form = profile.online_resources.online_resource.matching_gift_form
                    @guide = profile.online_resources.online_resource.guide
            )

            return

]
