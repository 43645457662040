angular.module("Vefa.googleAnalytics", [])

# --------------------------------------------------------------------------------------------------
# gaClick
# send custom event to Google Analytics (universal)
#
#
.directive 'gaClick', ->
    restrict: 'AC'
    scope: {}
    controllerAs: 'ctrl'
    bindToController:
        event: '=gaClick'
    controller: [
        '$scope'
        '$element'
        ($scope, $element) ->

            if @event? and ga?
                event = @event
                event.action = "click" unless event.action?
                $element.bind(
                    "click"
                    ->
                        ga(
                            'send',
                            'event',
                            event.category,
                            event.action,
                            event.label
                        )
                )

            return
    ]
