angular.module('Vefa.ajaxinterceptor', ['Vefa.details'])

.service '$partialService', ->
    @loadZones =
        dummy: {}

    @addLoadZone = ( obj ) =>
        @loadZones[obj.id] = obj

    @uploadPartial = (loadZone, html) ->
        if @loadZones[loadZone]
            @loadZones[loadZone].bindPartial html

    return this

# --------------------------------------------------------------------------------------------------
# Add chosen members to catalog and intercept various
# pHTML link calls and submissions to use ajax
.controller 'AjaxInterceptor',
[
    '$scope'
    '$http'
    '$sce'
    ($scope, $http, $sce) ->
        @bindHtml = (html) ->
            $sce.trustAsHtml html

        return this
]

.service '$ajaxInterceptor', [
    '$http',
    '$sce',
    ($http, $sce) ->
        # why use a service for simple HTTP GET? TODO: build out event broadcast/listed
        # notification through the service
        get: (url, success_func, error_func) ->
            $http
                .get( url )
                .success( success_func )
                .error( error_func )

        bindHtml: (html) ->
            $sce.trustAsHtml html
]

.directive 'interceptPageLoad', [
    '$ajaxInterceptor',
    ($ajaxService) ->
        restrict: 'EAC'
        replace: true
        templateUrl: 'intercept-load-more.html'
        scope: {}
        bindToController:
            loadMoreFrom: '@'
            pages: '@'
        controllerAs: "interceptCtrl"
        controller: [
            '$element'
            ($elm) ->
                page_piece =
                    if @loadMoreFrom.indexOf("?") isnt -1
                    then "&page="
                    else "?page="

                @text = "Load More"
                @results = ""
                @current_page = 1
                @in_process = false
                @is_unavailable = false

                @isDisabled =  ->
                    return (@in_process or @is_unavailable)

                @preCheck = ->
                    # this just calls completeLoad, but gives a more logical func name
                    @completeLoad()

                @completeLoad = ->
                    if @current_page is parseInt( @pages )
                        @is_unavailable = true
                        @text = "Loading Complete"

                @load = ($event) ->
                    $event.preventDefault()

                    if @current_page < @pages
                        @in_process = true
                        @current_page++

                    $ajaxService.get(
                        "#{@loadMoreFrom}#{page_piece}#{@current_page}"
                        (html) =>
                            @results = $ajaxService.bindHtml( @results + html )
                            @in_process = false
                            @completeLoad()
                        () =>
                            @is_unavailable = true
                            @in_process = false
                            @completeLoad()
                    )

                @preCheck()
                return
        ]
]

.directive 'interceptLoadMore',
[
    '$http'
    '$sce'
    ($http, $sce) ->
        restrict: 'EAC'
        replace: true
        scope:
            loadMoreFrom: '@'
            pages: '@'
        templateUrl: 'intercept-load-more.html'
        controller: 'AjaxInterceptor'
        link: (scope, elm, attrs, controller) ->
            scope.text = "Load More"
            scope.results = ''
            scope.current_page = 1
            scope.inProcess = false
            scope.isUnavailable = false

            # page_piece =
            #     if scope.loadMoreFrom.indexOf("?") isnt -1
            #     then "&page="
            #     else "?page="
            #
            # @completeLoad = ->
            #     if scope.current_page is parseInt scope.pages
            #         scope.isUnavailable = true
            #         scope.text = "Loading Complete"
            #
            # scope.isDisabled = ->
            #     return (scope.inProcess or scope.isUnavailable)
            #
            # scope.preCheck = =>
            #     # this just calls completeLoad, but gives a more logical func name
            #     do @completeLoad
            #
            # scope.loadMore = ($event) ->
            #     do $event.preventDefault
            #
            #     if scope.current_page < scope.pages
            #         scope.inProcess = true
            #         scope.current_page++
            #
            #     $http.get( scope.loadMoreFrom + page_piece + scope.current_page )
            #         .success (data) ->
            #             scope.results = controller.bindHtml( scope.results + data )
            #             scope.inProcess = false
            #             do @completeLoad
            #         .error ->
            #             scope.isUnavailable = true
            #             scope.inProcess = false
            #             do @completeLoad
            #
            # do scope.preCheck
]


.directive 'loadPartialFromSelection',
[
    '$http'
    '$sce'
    '$partialService'
    '$compile'
    '$closeService'
    ($http, $sce, $pS, $compile, $cS) ->
        restrict: 'EAC'
        controller: 'AjaxInterceptor'
        transclude: true
        replace: false
        scope: true
        template:"""<div ng-transclude></div>"""
        link: (scope, element, attrs, controller) ->
            scope.id = attrs.id
            # scope.partial = ''

            scope.bindPartial = (html) ->
                # scope.partial = controller.bindHtml html
                # document.getElementById(scope.id).innerHTML = html
                # element.html scope.results.content
                # $compile(element.contents())(scope)

                true

            $pS.addLoadZone scope

            return
]


.directive 'partialSelector',
[
    '$http'
    '$sce'
    '$partialService'
    '$closeService'
    ($http, $sce, $pS, $cS) ->
        restrict: 'EAC'
        replace: true
        transclude: true
        scope: true
        controller: 'AjaxInterceptor'
        template:
            """
            <a ng-click="loadMore($event)" ng-transclude></a>
            """
        link: (scope, element, attrs, controller) ->
            scope.key = attrs.key
            scope.value = attrs.value
            scope.bindTo = attrs.bindTo

            scope.loadMore = ($event) ->
                do $event.preventDefault

                $http.get( "?state=#{ scope.key }" )
                    .success (data) ->

                        document.getElementById('currentState').innerHTML = scope.value
                        document.getElementById('statStrata').innerHTML = data
                        $cS.toggle()
]
